<template>
  <div class="pb-5">
    <ul class="biz-breadcrumb">
      <li><a>{{$t('WIZARD.CONTINUE_REGISTRATION')}}</a></li>
    </ul>
    <div class="page">
      <div class="sub-title">
        <h1>{{$t('WIZARD.CONTINUE_REGISTRATION_FLOW')}}</h1>
      </div>
      <div class="p-1">
        <RegistrationFlow :status="status"/>
      </div>
    </div>
    <div class="mt-0">
      <router-view @onSave="onSave" v-bind="isRegistrationWizard"></router-view>
    </div>
    <div class="mt-2 text-center">
      <j-button variant="light" v-if="currentPage > 1 && currentPage != 10" v-on:click="refresh(false);">
        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
        {{$t('COMMON.GO_BACK')}}
      </j-button>
      <j-button variant="light" :class="{'ml-5': currentPage > 1 && currentPage != 10}" to="/supplier/manage">
        <font-awesome-icon icon="home"></font-awesome-icon>
        {{$t('HEADER.TO_TOP_PAGE')}}
      </j-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  
</style>
<script>
  import RegistrationFlow from '@/components/flow/RegistrationFlow.vue'
  import CompanyModel from '@/model/CompanyInfo.js';
  
  export default {
    components: { RegistrationFlow },
    data: () => ({
      status: {},
      currentPage: 1
    }),
    computed: {
      isRegistrationWizard(){
        return {
          isRegistrationWizard: true
        }
      }
    },
    created: function() {
      CompanyModel.getCompanyStatus(this.$store.state.user.companyCode).then((result) => {
        this.status = result.data;
        this.currentPage = 1;
        if(this.status.registrationCompleted) this.currentPage = 11;
        else if(this.status.addressRegistered) this.currentPage = 10;
        else if(this.status.bankAccountRegistered) this.currentPage = 9;
        else if(this.status.productNameRegistered) this.currentPage = 8;
        else if(this.status.prRegistered) this.currentPage = 7;
        else if(this.status.verificationRegistered) this.currentPage = 6;
        else if(this.status.companyAddonRegistered) this.currentPage = 5;
        else if(this.status.deliverableRegistered) this.currentPage = 4;
        else if(this.status.categoryRegistered) this.currentPage = 3;
        else if(this.status.buyerRegistered) this.currentPage = 2;
        this.changePage();
      }).catch(reason => {
        this.$errorMessage(undefined, {reason});
      });
    },
    methods: {
      onSave(){
        this.refresh();
      },
      refresh(isForward = true){
        this.topFunction();
        if(isForward){
          this.currentPage += 1;
          this.changePage();
        }else{
          if(this.currentPage === 3){
            this.currentPage -= this.status.openSupplier ? 1 : 2;
          }else{
            this.currentPage -= 1;
          }
          
          if(this.currentPage < 1) this.currentPage = 1;
          this.changePage();
        }
      },
      changePage(){
        if(this.currentPage > 11) this.currentPage = 11;
        if(this.currentPage === 1) {
          if(!this.status.openSupplier){
            this.currentPage = 2;
            this.changePage();
            return;
          }
          this.$router.push('/supplier/registration/buyer-info');
        }else if(this.currentPage === 2) {
          this.status.buyerRegistered = true;
          this.status.categoryRegistered= false;
          this.$router.push('/supplier/registration/category-info');
        }else if(this.currentPage === 3) {
          this.status.categoryRegistered = true;
          this.status.deliverableRegistered = false;
          this.$router.push('/supplier/registration/delivery-location-info');
        }else if(this.currentPage === 4) {
          this.status.deliverableRegistered = true;
          this.status.companyAddonRegistered = false;
          this.$router.push('/supplier/registration/addon-info');
        }else if(this.currentPage === 5) {
          this.status.companyAddonRegistered = true;
          this.status.verificationRegistered = false;
          this.$router.push('/supplier/registration/verification-info'); 
        }else if(this.currentPage === 6) {
          this.status.verificationRegistered = true;
          this.status.prRegistered = false;
          this.$router.push('/supplier/registration/pr');
        }else if(this.currentPage === 7) {
          this.status.prRegistered = true;
          this.status.productNameRegistered = false;
          this.$router.push('/supplier/registration/product-name-info'); 
        }else if(this.currentPage === 8) {
          this.status.productNameRegistered = true;
          if(!this.status.openSupplier){
            this.currentPage = 10;
            this.changePage();
            return;
          }
          this.$router.push('/supplier/registration/payment-account-info');
        }else if(this.currentPage === 9) {
          this.status.bankAccountRegistered = true;
          this.$router.push('/supplier/registration/office-verification-info');
        }else if(this.currentPage === 10) {
          this.status.addressRegistered = true;
          this.status.registrationCompleted = true;
          this.$router.push('/supplier/registration/registration-finish');
        }else if(this.currentPage === 11) {
          
          this.$router.push('/supplier/registration/register-signer');
        }
        this.$store.commit('setStatus', this.status);
      },
      topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      
    }
  }
</script>